<script setup>
import { computed } from 'vue'

const props = defineProps({
  checked: {
    type: [Array, Boolean],
    default: false,
  },
  value: {
    type: String,
    default: null,
  },
})

const emit = defineEmits(['update:checked'])

const proxyChecked = computed({
  get() {
    return props.checked
  },

  set(value) {
    emit('update:checked', value)
  },
})
</script>

<template>
  <input
    v-model="proxyChecked"
    type="checkbox"
    :value
    class="rounded border-gray-300 text-indigo-600 shadow-sm focus:ring-indigo-500 dark:border-gray-700 dark:bg-gray-900 dark:focus:ring-indigo-600 dark:focus:ring-offset-gray-800"
  />
</template>
